 import {ApiConsumer} from 'api/ApiConsumer'
import {API_ROUTES} from 'routes/api'
import Loader from 'components/Loader'
import {useContext, useEffect, useState} from 'react'
import {PageTitleContext} from 'context/PageTitleContext'
import {CrumbsContext} from 'context/CrumbsContext'
import $ from 'jquery'
import {useAuthentication} from 'hooks/AuthenticationHook'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'
import {faGlobe } from '@fortawesome/free-solid-svg-icons'
import {faContactBook, faEnvelope, faUser} from '@fortawesome/free-regular-svg-icons'

export default function Profile() {
    const [details, setDetails] = useState({})
    const [countries, setCountries] = useState([])
    const [loader, setLoader] = useState(false)

    let {user} = useAuthentication()
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    function handleSubmit(e) {
        e.preventDefault()
        setLoader(true)

        let form = $('#profileForm').get(0)
        let data = new FormData(form)

        ApiConsumer.put(API_ROUTES.USERS.UPDATE(user()?.id), data)
            .then(res => {
                console.log(res)
                setLoader(false)
            })
            .catch(err => {
                console.log('URL: ', API_ROUTES.USERS.UPDATE(user()?.id))
                console.error('Error: ', err)
                setLoader(true);
            });
    }

    useEffect(() => {
        setTitle('Your Account')
        setCrumbs([{'name': 'Administration', 'link': '/monitoring'}, {'name': 'Your Account', 'link': '/venue'}])
        setLoader(true)

        ApiConsumer.get(API_ROUTES.USERS.SINGLE(user()?.id))
            .then(res => {
                setDetails(res.data)
                setDetails((prevState) => ({
                    ...prevState,
                    [`country`]: res.data?.country?.id,
                }))
            })
            .catch(err => console.error(err))
            .finally(() => setLoader(false))

        ApiConsumer.get(API_ROUTES.COUNTRIES.ALL)
            .then(res => {
                setCountries(res.data)
            })
            .catch(err => console.error(err))
            .finally(() => setLoader(false))
    }, [])

    return <>

        <div className='min-h-full px-5 py-5 mt-0 h-screen'>
            <h3 className='mb-2 font-semibold profile-overview-title'>Personal Information</h3>

            <div className='bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6'>
                <form id='profileForm' onSubmit={handleSubmit}>
                    <div className='md:grid md:grid-cols-3 md:gap-6'>
                        <div className='mt-5 md:mt-8 md:col-span-2'>
                            <div className='grid grid-cols-6 gap-6'>
                                <div className='mt-1 flex rounded-md col-span-6 sm:col-span-3'>
                                    <span
                                        className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                                        <FontAwesomeIcon icon={faUser} className='h-4 w-4' aria-hidden='true'/>
                                    </span>
                                    <input
                                        type='text'
                                        name='first_name'
                                        id='first_name'
                                        placeholder='First name'
                                        value={details?.first_name}
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`first_name`]: event.target.value,
                                            }))
                                        }}
                                        className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-r-md border-transparent focus:border-transparent focus:ring-0 sm:text-sm'
                                    />
                                </div>

                                <div className='mt-1 flex rounded-md col-span-6 sm:col-span-3'>
                                    <span
                                        className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                                        <FontAwesomeIcon icon={faUser} className='h-4 w-4' aria-hidden='true'/>
                                    </span>
                                    <input
                                        type='text'
                                        placeholder='Last name'
                                        name='last_name'
                                        id='last_name'
                                        autoComplete='family-name'
                                        value={details?.last_name}
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`last_name`]: event.target.value,
                                            }))
                                        }}
                                        className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                    />
                                </div>

                                <div className='mt-1 flex rounded-md col-span-6 sm:col-span-3'>
                                    <span
                                        className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                                        <FontAwesomeIcon icon={faEnvelope} className='h-4 w-4' aria-hidden='true'/>
                                    </span>
                                    <input
                                        type='text'
                                        name='email'
                                        placeholder='Email address'
                                        id='email'
                                        autoComplete='email'
                                        value={details?.email}
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`email`]: event.target.value,
                                            }))
                                        }}
                                        readOnly
                                        className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                    />
                                </div>

                                <div className='mt-1 flex rounded-md col-span-6 sm:col-span-3'>
                                    <span
                                        className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                                        <FontAwesomeIcon icon={faContactBook} className='h-4 w-4' aria-hidden='true'/>
                                    </span>
                                    <input
                                        type='text'
                                        placeholder='Phone number'
                                        name='contact_number'
                                        id='contact_number'
                                        value={details?.contact_number}
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`contact_number`]: event.target.value,
                                            }))
                                        }}
                                        autoComplete={false}
                                        className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                    />
                                </div>

                                <div className='mt-1 flex rounded-md col-span-6 sm:col-span-3'>
                                    <span
                                        className='inline-flex items-center px-3 rounded-l-md bg-gray-300 text-gray-500 sm:text-sm'>
                                        <FontAwesomeIcon icon={faGlobe} className='h-4 w-4' aria-hidden='true'/>
                                    </span>
                                    <select
                                        id='country'
                                        name='country'
                                        autoComplete='country-name'
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`country`]: event.target.value,
                                            }))
                                        }}
                                        value={details?.country}
                                        className='flex-1 min-w-0 block w-full px-3 py-2 bg-gray-100 rounded-none rounded-r-md focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm'
                                    >
                                        <option value='' enabled={false} selected={true}>Country</option>
                                        {
                                            countries.map(country => {
                                                return <option key={country.id}
                                                               value={country.id}>{country.name}</option>
                                            })
                                        }
                                    </select>
                                </div>

                            </div>
                        </div>

                        <div className='mt-5 md:mt-0 md:col-span-3'>
                            <button type='submit'
                                    className='inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500s'>
                                Update Details
                            </button>
                        </div>
                    </div>
                </form>
            </div>

            {
                loader ? <Loader upload_message={'Updating user details'} full_height={true}/> : ''
            }
        </div>
    </>
}
