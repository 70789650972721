import {useEffect, useState} from "react";
import {Chart} from "react-google-charts";
import {ApiConsumer} from "api/ApiConsumer";

export function Line({url}) {
    const [data, setData] = useState([])

    const options = {
        title: "",
        legend: {position: "bottom"},
    };

    useEffect(() => {
        ApiConsumer.get(url)
            .then(res => {
                let temp = res.data
                let heading = [
                    "Month",
                    "BTV",
                    "Duma FM",
                    "GabzFM",
                    "RB1",
                    "RB2",
                    "Yarona FM",
                    "NowTV/BTV 1",
                    "Choppies Radio",
                    "YTV"
                ]
                //TODO: Need to do this better. Just too tired right now
                const jan = ['January']
                const feb = ['February']
                const mar = ['March']
                const keys = Object.keys(res.data);
                keys.forEach(function (key, i) {
                    if (res.data[key]?.month === 1) {
                        jan.push(res.data[key][Object.keys(temp[0])[2]]);
                    }
                    if (res.data[key]?.month === 2) {
                        feb.push(res.data[key][Object.keys(temp[0])[2]]);
                    }
                    if (res.data[key]?.month === 3) {
                        mar.push(res.data[key][Object.keys(temp[0])[2]]);
                    }
                });
                jan.push(0)
                feb.push(0)
                mar.push(0)
                setData([heading]
                    .concat([jan])
                    .concat([feb])
                    .concat([mar])
                )
                console.log(data)
            })
            .catch(err => {
                console.error(err)
            })
            .finally(() => {
            })
    }, [url])
    return <>
        <Chart
            className='rounded shadow-lg'
            chartType="LineChart"
            width="100%"
            height="400px"
            data={data}
            options={options}
        />
    </>
}
