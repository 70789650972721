import {EyeIcon, PlayIcon} from "@heroicons/react/solid";
import {MusicSlideOverContext} from "context/MusicSlideOverContext";
import {useContext} from "react";
import {MusicPlayerContext} from "context/MusicPlayer";
import icon from 'assets/images/music-icon.svg'

export default function MusicCard({music, number = 0}) {
    console.log(music)
    let {setMusic} = useContext(MusicSlideOverContext)
    const {setPlaying} = useContext(MusicPlayerContext);
    return <div key={music?.id} className="bg-white shadow-lg rounded p-3">
        <div className="group relative">
            <img className="w-full md:w-full block rounded"
                 src={music?.artwork ?? icon} alt="" width={133} height={133}/>
            <div
                className="absolute bg-black rounded bg-opacity-0 group-hover:bg-opacity-60 w-full h-full top-0 flex items-center group-hover:opacity-100 transition justify-evenly">
                <div className="absolute top-0 right-0 -mr-5 mt-1 h-16 w-16 z-0">
                    {
                        music?.count ?
                            <span
                                className="inline-flex items-center justify-center h-10 w-10 rounded-full bg-pink-600">
                        <span className="font-medium leading-none text-white">{number}</span>
                    </span> : null
                    }
                </div>
                <button onClick={() => {
                    setMusic({...music, ...{open: true}})
                }}
                        className="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                    <EyeIcon className={'w-7'}/>
                </button>

                <button onClick={() => {
                    let track = {
                        music: {
                            id: music.id,
                            image: music?.artwork ?? icon,
                            title: music.title,
                            artist: music.artist,
                            file: music.file
                        }
                    }
                    setPlaying(track)
                }}
                        className="hover:scale-110 text-white opacity-0 transform translate-y-3 group-hover:translate-y-0 group-hover:opacity-100 transition">
                    <PlayIcon className={'w-7'} onClick={() => {
                        let track = {
                            music: {
                                id: music.id,
                                image: music?.artwork ?? icon,
                                title: music.title,
                                artist: music.artist,
                                file: music.file ?? music?.recording?.recording
                            }
                        }
                        setPlaying(track)
                    }}/>
                </button>
            </div>
        </div>
        <div className="pt-3">
            <h3 className="text-gray-600 text-lg">{music?.title}</h3>
            <p className="text-gray-400">{music?.artist} - {music?.album}</p>
        </div>
    </div>
}
