import {NavLink, useHistory} from "react-router-dom";
import imonitor from 'assets/images/imonitor.svg'
import cosbots from 'assets/images/cosbots-logo.png'
import {useAuthentication} from "hooks/AuthenticationHook";
import {useContext, useState} from "react";
import axios from "axios";
import {API_ROUTES} from "routes/api";
import * as Sentry from '@sentry/react'
import {CogIcon} from "@heroicons/react/outline";
import {NotificationContext} from "context/NotificationContext";

export default function Login() {
    const [details, setDetails] = useState({})
    const [loading, setLoading] = useState(false)
    let history = useHistory()
    let {setNotification} = useContext(NotificationContext)
    let {setAuthentication} = useAuthentication()

    function login() {
        setLoading(true)
        axios
            .post(API_ROUTES.AUTHENTICATION.LOGIN, details)
            .then((res) => {
                setAuthentication(res.data)
                history.push('/')
                setNotification({show: true, title:'Welcome', message:`Welcome ${res.data?.name}`, type: 'success'})
            })
            .catch((err) => {
                setNotification({show: true, title:'Login error', message:'Could not log you in, please try again later.', type: 'error'})
                Sentry.captureException(err)
            })
            .finally(() => setLoading(false))
    }

    return (
        <>
            <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
                <div className="sm:mx-auto sm:w-full sm:max-w-md">
                    <img
                        className="mx-auto h-12 w-auto"
                        src={imonitor}
                        alt="iMonitor Logo"
                    />
                    <img
                        className="mx-auto h-12 w-auto mt-5"
                        src={cosbots}
                        alt="COSBOTS Logo"
                    />
                    <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">Sign in to iMonitor</h2>
                </div>

                <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10 border-2">
                        <form className="space-y-6" onSubmit={(event) => {
                            event.preventDefault()
                            login()
                        }} method="POST">
                            <div>
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email address
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`username`]: event.target.value,
                                            }))
                                        }}
                                        value={details?.username}
                                    />
                                </div>
                            </div>

                            <div>
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <div className="mt-1">
                                    <input
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        required
                                        className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                                        onChange={(event) => {
                                            setDetails((prevState) => ({
                                                ...prevState,
                                                [`password`]: event.target.value,
                                            }))
                                        }}
                                        value={details.password}
                                    />
                                </div>
                            </div>

                            <div className="flex items-center justify-between text-center">
                                <div className="text-sm w-full">
                                    <NavLink to={'/forgot-password'}
                                             className="font-medium text-indigo-600 hover:text-indigo-500">
                                        Forgot your password?
                                    </NavLink>
                                </div>
                            </div>

                            <div>
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                                >
                                    {
                                        loading ? <CogIcon
                                                className="animate-spin h-6 w-6 content-center text-center mx-auto"/> :
                                            <>Sign in</>
                                    }
                                </button>
                            </div>
                            <div className="flex items-center justify-between text-center">
                                <div className="text-sm text-center w-full">
                                    <NavLink to={'/register'}
                                             className="font-medium text-center text-indigo-600 hover:text-indigo-500">
                                        Don't have an account? Click here to register.
                                    </NavLink>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    )
}
