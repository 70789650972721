import {BroadcastFilter} from "components/Broadcast/filter";
import {PageTitleContext} from "context/PageTitleContext";
import {useContext, useEffect, useState} from "react";
import BroadcastCards from "components/Broadcast/cards";
import {CrumbsContext} from "context/CrumbsContext";


export default function Monitoring() {
    const [searchDetails, setSearch] = useState('')
    const [term, setTerm] = useState('')
    let {setTitle} = useContext(PageTitleContext)
    let {setCrumbs} = useContext(CrumbsContext)

    function filterBroadcast() {
        setTerm(searchDetails.term)
        console.log(searchDetails)
    }

    useEffect(() => {
        setTitle('Broadcast Monitoring')
        setCrumbs([{'name': 'Monitoring', 'link': '/monitoring'}, {'name': 'Broadcast Monitoring', 'link': '/monitoring'}])
    }, [])

    return <>
        <div className="min-h-full px-5 py-5 mt-0">
            <BroadcastFilter setDetails={setSearch} details={searchDetails} options={[]} search={filterBroadcast}/>
            <BroadcastCards term={term}/>
        </div>
    </>
}
