import {Fragment, useContext} from 'react'
import {Dialog, Transition} from '@headlessui/react'
import {XIcon, DownloadIcon, PlayIcon} from '@heroicons/react/outline'
import {MusicSlideOverContext} from "context/MusicSlideOverContext";
import {MusicPlayerContext} from "context/MusicPlayer";
import icon from "assets/images/music-icon.svg";
import spotify from "assets/images/brands/spotify.svg";
import deezer from "assets/images/brands/deezer.svg";
import youtube from "assets/images/brands/youtube.svg";
import {useUtils} from "hooks/UtilityHook";

export default function SlideDetail() {
    let {music, setMusic} = useContext(MusicSlideOverContext)
    let {setPlaying} = useContext(MusicPlayerContext)
    let {downloadFile} = useUtils()


    return (
        <Transition.Root show={music?.open ?? false} as={Fragment}>
            <Dialog as="div" className="fixed inset-0 overflow-hidden z-50" onClose={() => setMusic({open: false})}>
                <div className="absolute inset-0 overflow-hidden">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-500"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-500"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"/>
                    </Transition.Child>
                    <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                        <Transition.Child
                            as={Fragment}
                            enter="transform transition ease-in-out duration-500 sm:duration-700"
                            enterFrom="translate-x-full"
                            enterTo="translate-x-0"
                            leave="transform transition ease-in-out duration-500 sm:duration-700"
                            leaveFrom="translate-x-0"
                            leaveTo="translate-x-full"
                        >
                            <div className="pointer-events-auto relative w-96">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-500"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-500"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute top-0 left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                                        <button
                                            type="button"
                                            className="rounded-md text-gray-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                                            onClick={() => setMusic({open: false})}
                                        >
                                            <span className="sr-only">Close panel</span>
                                            <XIcon className="h-6 w-6" aria-hidden="true"/>
                                        </button>
                                    </div>
                                </Transition.Child>
                                <div className="h-full overflow-y-auto bg-white p-8">
                                    <div className="space-y-6 pb-16">
                                        <div>
                                            <h3 className="font-medium text-gray-900">Information</h3>
                                            <dl className="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                                                {
                                                    music.acrid ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">ID</dt>
                                                            <dd className="text-gray-900">{music.acrid}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music.title ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Title</dt>
                                                            <dd className="text-gray-900">{music.title}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music.artist ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Artist</dt>
                                                            <dd className="text-gray-900">{music.artist}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music.composer ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Composer</dt>
                                                            <dd className="text-gray-900">{music.composer}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music.album ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Album</dt>
                                                            <dd className="text-gray-900">{music.album}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music.label ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Label</dt>
                                                            <dd className="text-gray-900">{music.label}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music.publisher ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Publisher</dt>
                                                            <dd className="text-gray-900">{music.publisher}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music.duration ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Duration</dt>
                                                            <dd className="text-gray-900">{music.duration}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.isrc ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">ISRC</dt>
                                                            <dd className="text-gray-900">{music?.isrc ?? '-'}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.iswc ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">ISWC</dt>
                                                            <dd className="text-gray-900">{music?.iswc ?? '-'}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.upc ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">UPC</dt>
                                                            <dd className="text-gray-900">{music?.upc ?? '-'}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.release_year ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Release Year</dt>
                                                            <dd className="text-gray-900">{music?.release_year}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.uploaded_by ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Uploaded By</dt>
                                                            <dd className="text-gray-900">
                                                                {`${music?.uploaded_by?.first_name} ${music?.uploaded_by?.last_name}`}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.country_produced ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Country Produced</dt>
                                                            <dd className="text-gray-900">
                                                                {`${music?.country_produced}`}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.language ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Language</dt>
                                                            <dd className="text-gray-900">
                                                                {`${music?.language}`}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.timestamp ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Timestamp</dt>
                                                            <dd className="text-gray-900">{music?.timestamp?.replace('T', ' ')}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.created ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Created</dt>
                                                            <dd className="text-gray-900">{music?.created?.replace('T', ' ')}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.updated ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Last Updated</dt>
                                                            <dd className="text-gray-900">{music?.updated?.replace('T', ' ')}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.count ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Play Count</dt>
                                                            <dd className="text-gray-900">{music?.count}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.play_duration ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Total Duration</dt>
                                                            <dd className="text-gray-900">{music?.play_duration}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.catalogue ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Catalogue</dt>
                                                            <dd className="text-gray-900">{music?.catalogue}</dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.spotify_id ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Spotify</dt>
                                                            <dd className="text-gray-900">
                                                                <a href={`https://open.spotify.com/track/${music?.spotify_id}`} target='_blank'
                                                                   rel='noreferrer'>
                                                                    <img className='h-5 text-right cursor-pointer'
                                                                         src={spotify} alt=''/>
                                                                </a>
                                                            </dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.deezer_id ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Deezer</dt>
                                                            <dd className="text-gray-900">
                                                                <a href={`https://www.deezer.com/us/track/${music?.deezer_id}`} target='_blank'
                                                                   rel='noreferrer'>
                                                                    <img className='h-5 text-right cursor-pointer'
                                                                         src={deezer} alt=''/>
                                                                </a>
                                                            </dd>
                                                        </div> : null
                                                }
                                                {
                                                    music?.youtube_vid ?
                                                        <div className="flex justify-between py-3 text-sm font-medium">
                                                            <dt className="text-gray-500">Youtube</dt>
                                                            <dd className="text-gray-900">
                                                                <a href={`https://www.youtube.com/watch?v=${music?.youtube_vid}`} target='_blank'
                                                                   rel='noreferrer'>
                                                                    <img className='h-5 text-right cursor-pointer'
                                                                         src={youtube} alt=''/>
                                                                </a>
                                                            </dd>
                                                        </div> : null
                                                }
                                                <div className="flex justify-between py-3 text-sm font-medium">
                                                    <dt className="text-gray-500">Download</dt>
                                                    <dd className="text-gray-900">
                                                        <DownloadIcon onClick={() => {
                                                            downloadFile(music.title, music.file ?? music?.recording?.recording)
                                                        }}
                                                                      className={'h-5 w-5 cursor-pointer text-blue-500'}/>
                                                    </dd>
                                                </div>
                                                <div className="flex justify-between py-3 text-sm font-medium">
                                                    <dt className="text-gray-500">Play</dt>
                                                    <dd className="text-gray-900">
                                                        <PlayIcon className={'h-5 w-5 cursor-pointer text-green-500'}
                                                                  onClick={() => {
                                                                      let track = {
                                                                          music: {
                                                                              id: music.id,
                                                                              image: {icon},
                                                                              title: music.title,
                                                                              artist: music.artist,
                                                                              file: music.file ?? music?.recording?.recording
                                                                          }
                                                                      }
                                                                      setPlaying(track)
                                                                  }}/>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}
