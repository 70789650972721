import {useEffect, useRef, useState} from "react";
import {Chart} from "react-google-charts";
import Loader from "components/Loader";

export function MonthlyCharts({results, loading}) {
    const exportRef = useRef();
    const [data, setData] = useState([])
    const [artistData, setArtistData] = useState([])
    const [intArtistData, setIntArtistData] = useState([])
    const [natArtistData, setNatArtistData] = useState([])
    const [songData, setSongData] = useState([])
    const [recogData, setRecogData] = useState([])
    const IntOptions = {
        title: "",
        legend: {position: 'bottom'},
        chartArea: {width: "90%"},
        isStacked: false,
        hAxis: {
            title: "",
            minValue: 0,
        },
        series: {
            0: {color: '#2980b9', axis: 'National'},
            1: {color: '#e74c3c', axis: 'International'}
        },
        axes: {
            y: {
                National: {label: 'parsecs'},
                International: {side: 'right', label: 'apparent magnitude'}
            }
        },
        vAxis: {
            title: "",
            textPosition: 'none'

        },

    };

    const options = {
        title: "",
        legend: {position: 'bottom'},
        chartArea: {width: "90%"},
        isStacked: true,
        hAxis: {
            title: "",
            minValue: 0,
        },
        vAxis: {
            title: "",
            textPosition: 'none'

        },
        series: {
            0: {color: '#2980b9'}
        }
    };

    useEffect(() => {
        console.log(results)
        function percentage(top, bottom) {
            return parseFloat((top / (top + bottom) * 100)).toFixed(2)
        }

        if (results?.catalogue_data !== null && results?.catalogue_data !== undefined) {
            let result = [];
            result.push(["Channel", "National", {role: 'annotation'}, "International", {role: 'annotation'}])
            results?.catalogue_data?.map(res => {
                result.push([res.broadcast_stream__name, res.national, percentage(res.national, res.international), res.international, percentage(res.international, res.national)])
            })
            setData(result)
        }

        if (results?.recognition !== null && results?.recognition !== undefined) {
            let result = [];
            result.push(["Channel", "Recognised", {role: 'annotation'}, "Unrecognised", {role: 'annotation'}])
            results?.recognition?.map(res => {
                result.push([res.broadcast_stream__name, res.recognised, percentage(res.recognised, res.unrecognised), res.unrecognised, percentage(res.unrecognised, res.recognised)])
            })
            setRecogData(result)
        }

        if (results?.artist_data !== null && results?.artist_data !== undefined) {
            let result = [];
            result.push(["Artist", "Count", {role: 'annotation'}])
            results?.artist_data?.map(res => {
                result.push([res.artist, res.count, res.count])
            })
            setArtistData(result)
        }

        if (results?.international_artist_data !== null && results?.international_artist_data !== undefined) {
            let result = [];
            result.push(["Artist", "Count", {role: 'annotation'}])
            results?.international_artist_data?.map(res => {
                result.push([res.artist, res.count, res.count])
            })
            setIntArtistData(result)
        }

        if (results?.national_artist_data !== null && results?.national_artist_data !== undefined) {
            let result = [];
            result.push(["Artist", "Count", {role: 'annotation'}])
            results?.national_artist_data?.map(res => {
                result.push([res.artist, res.count, res.count])
            })
            setNatArtistData(result)
        }

        if (results?.title_data !== null && results?.title_data !== undefined) {
            let result = [];
            result.push(["Title", "Count", {role: 'annotation'}])
            results?.title_data?.map(res => {
                result.push([res.title, res.count, res.count])
            })
            setSongData(result)
        }
    }, [results])
    return <>
        <section className="grid grid-cols-1 sm:grid-cols-4 gap-4 mt-5">
            <div className="sm:col-span-4">
                <div className="box">
                    {
                        loading ? <Loader upload_message={'loading'} full_height={true}/> :
                            <div className="box-wrapper">
                                <h3 className='font-semibold text-2xl profile-overview-title text-center pb-2'>Top 10
                                    Most played
                                    Artists</h3>
                                <Chart
                                    chartType="ColumnChart"
                                    width="100%"
                                    height={`500px`}
                                    data={artistData}
                                    options={options}
                                    className={'rounded'}
                                />
                            </div>
                    }
                </div>
            </div>
        </section>

        <section className="grid grid-cols-1 sm:grid-cols-4 gap-4 mt-5">
            <div className="sm:col-span-4">
                <div className="box">
                    {
                        loading ? <Loader upload_message={'loading'} full_height={true}/> :
                            <div className="box-wrapper">
                                <h3 className='font-semibold text-2xl profile-overview-title text-center pb-2'>
                                    Top 10 Most played National Artists
                                </h3>
                                <Chart
                                    chartType="ColumnChart"
                                    width="100%"
                                    height={`500px`}
                                    data={natArtistData}
                                    options={options}
                                    className={'rounded'}
                                />
                            </div>
                    }
                </div>
            </div>
        </section>

        <section className="grid grid-cols-1 sm:grid-cols-4 gap-4 mt-5">
            <div className="sm:col-span-4">
                <div className="box">
                    {
                        loading ? <Loader upload_message={'loading'} full_height={true}/> :
                            <div className="box-wrapper">
                                <h3 className='font-semibold text-2xl profile-overview-title text-center pb-2'>
                                    Top 10 Most played International Artists
                                </h3>
                                <Chart
                                    chartType="ColumnChart"
                                    width="100%"
                                    height={`500px`}
                                    data={intArtistData}
                                    options={{...options, series: {
                                            0: {color: '#e74c3c'}
                                        }}}
                                    className={'rounded'}
                                />
                            </div>
                    }
                </div>
            </div>
        </section>

        <section className="grid grid-cols-1 sm:grid-cols-4 gap-4 mt-5">
            <div className="sm:col-span-4">
                <div className="box">
                    {
                        loading ? <Loader upload_message={'loading'} full_height={true}/> :
                            <div className="box-wrapper">
                                <h3 className='font-semibold text-2xl profile-overview-title text-center pb-2 pt-5'>Top
                                    10 Most played
                                    Songs</h3>
                                <Chart
                                    chartType="ColumnChart"
                                    width="100%"
                                    height={`500px`}
                                    data={songData}
                                    options={options}
                                    className={'rounded'}
                                />
                            </div>
                    }
                </div>
            </div>
        </section>

        <section className="grid grid-cols-1 sm:grid-cols-4 gap-4 mt-5">
            <div className="sm:col-span-4">
                <div className="box">
                    {
                        loading ? <Loader upload_message={'loading'} full_height={true}/> :
                            <div className="box-wrapper">
                                <h3 className='font-semibold text-2xl profile-overview-title text-center pb-2 pt-5'>Local
                                    vs International
                                    Playtime(%)</h3>
                                <Chart
                                    chartType="ColumnChart"
                                    width="100%"
                                    height={`500px`}
                                    data={data}
                                    options={IntOptions}
                                    className={'rounded'}
                                />
                            </div>
                    }
                </div>
            </div>
        </section>

        <section className="grid grid-cols-1 sm:grid-cols-4 gap-4 mt-5">
            <div className="sm:col-span-4">
                <div className="box">
                    {
                        loading ? <Loader upload_message={'loading'} full_height={true}/> :
                            <div className="box-wrapper">
                                <h3 className='font-semibold text-2xl profile-overview-title text-center pb-2 pt-5'>Recognised vs Unrecognised</h3>
                                <Chart
                                    chartType="ColumnChart"
                                    width="100%"
                                    height={`500px`}
                                    data={recogData}
                                    options={IntOptions}
                                    className={'rounded'}
                                />
                            </div>
                    }
                </div>
            </div>
        </section>
    </>
}
