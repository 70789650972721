import {ApiConsumer} from "api/ApiConsumer";
import {API_ROUTES} from "routes/api";
import {useEffect, useState} from "react";
import {BroadcastCard} from "components/Broadcast/card";
import Loader from "components/Loader";

export default function BroadcastCards({term=''}) {
    const [channels, setChannels] = useState([])
    const [original, setOriginal] = useState([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        setLoading(true)
        ApiConsumer.get(API_ROUTES.BROADCAST.CHANNELS)
            .then(res => {
                setOriginal(res.data)
                setChannels(res.data)
            })
            .catch(err => console.error(err))
            .finally(() => setLoading(false))
    }, [])

    useEffect(() => {
        console.log(channels.filter(x => x.name.includes(term)))
        setChannels(original.filter(x => x.name.includes(term)))
    }, [term])

    return <>
        {
            loading ? <Loader upload_message={'loading'} full_height={true}/> :
                <div className="mt-6 grid gap-5 grid-cols-1 md:grid-cols-3 lg:grid-cols-5">
                    {
                        channels.map(broadcast => {
                            return <BroadcastCard broadcast={broadcast}/>
                        })
                    }
                </div>
        }
    </>
}
